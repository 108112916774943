import React from "react";
import {connect} from "react-redux";
import Widget from "components/Widget/index";
import {Link} from "react-router-dom";
import CircularProgress from "components/CircularProgress";
import PropertiesItemCard from "./PropertiesItemCard";
import {getBuyerProperties} from "../../../appRedux/actions";
import {Tabs, Button, Avatar, Card} from 'antd';
import '../dashboard/propertycss/PropertiesItemCard.css';
import moment from 'moment';
import { Badge } from 'antd';
const { TabPane } = Tabs;
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});


class PropertiesListing extends React.Component {

  state ={
    propStatusClass : "liked",
  }

  componentDidMount() {

    this.props.getBuyerProperties();
  }

  handlePropStatus = (value) =>{
    this.setState({propStatusClass : value})
  }

  getColor(overall) {
    if(overall <= 2){
      return <i className="icon icon-circle gx-fs-xxs" style={{color:'#FF0700'}}/>
    }else if(overall <= 4 && overall >= 3){
      return <i className="icon icon-circle gx-fs-xxs" style={{color:'#ffa334'}}/>
    }else if(overall <= 6 && overall >= 5){
      return <i className="icon icon-circle gx-fs-xxs" style={{color:'#ffce1f'}}/>
    }else if(overall <= 8 && overall >= 7){
      return  <i className="icon icon-circle gx-fs-xxs" style={{color:'#fef100'}}/>
    } else if(overall <= 10 && overall >= 9){
      return <i style={{color:'#80D726'}} className="icon icon-circle gx-fs-xxs"/>


    }
  }

  getColorCode(overall) {
    if(overall === ""){
      return '#515756';
    }else if(overall <= 2){
      return '#FF0700';
    }else if(overall <= 4 && overall >= 3){
      return '#FF7800';
    }else if(overall <= 6 && overall >= 5){
      return '#ffce1f';
    }else if(overall <= 8 && overall >= 7){
      return '#FEF100';
    } else if(overall <= 10 && overall >= 9){
      return '#80D726';
    }
  }
  getNullNumber(overall) {
    if(overall === ""){
      return 'x';
    }else if(overall >= 0){
      return overall;
    }
  }

  render() {
    const {loader, data} = this.props;
    const {propStatusClass} = this.state;
    // console.log(moment(data && data[0].createdAt).add(2, 'days').fromNow())

    const operations = data && data.length > 0 && <div className="gx-mx-sm-2">
      {/*{createdDate}*/}
      <p className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
        <i className="icon icon-forward gx-fs-lg gx-d-inline-flex gx-vertical-align-middle"/>
        <Link to="listing"> Browse All
        </Link>
      </p>
    </div>;

    let liked = data && data ? data.filter((data) => data.propStatus === 'liked') : [];
    let disliked = data && data ? data.filter((data) => data.propStatus === 'disliked') : [];
    let hidden = data && data ? data.filter((data) => data.propStatus === 'hidden') : [];
    return (
      <>


      <Widget >
        <div className="ant-row-flex gx-justify-content-between gx-mb-3 gx-mb-sm-4 gx-dash-search">
          <h2 className="h2 gx-mb-3 gx-mb-sm-1 gx-mr-2">Recent Properties</h2>
        </div>
        <Tabs tabBarExtraContent={operations} animated={false}>
          <TabPane tab={
            <div>
              Liked{' '}<Avatar style={{ color: '#fff', backgroundColor: '#52c41a' }} className="gx-size-20" alt="">{liked.length}</Avatar>
            </div>
          } key="1">
            {loader ? <CircularProgress className="gx-loader-400"/> :
              liked && liked.length >0 ? liked.map((data, index) =>
                // <PropertiesItemCard key={index} data={data}  handlePropStatus={(value)=>this.handlePropStatus(value)} propStatusClass={propStatusClass} />
                  <div className={`gx-media gx-featured-item padding propertiesitemcard__hidden`} style={{borderRadius:'10px'}} key={index}>
                    {/*{isFeatured === true ? <Tag color="orange"><span className="gx-text-uppercase">featured</span></Tag> : null}*/}

                    <div className="gx-featured-thumb">
                      <img className="gx-rounded-lg gx-width-175" height="100" src={data.garage} alt="..."/>
                      {/*<Tag className="gx-rounded-xs gx-bg-orange gx-border-orange gx-text-white">Featured</Tag>*/}
                    </div>
                    <div className="gx-media-body gx-featured-content">
                      <div className="gx-featured-content-left">

                        {/*<Tag className="gx-rounded-xs" color="#06BB8A">FOR SALE</Tag>*/}
                        <h3 className="gx-mb-2">{data.address}</h3>

                        <p className=" gx-mb-1">MLS: {data.mls}</p>

                        <div className="ant-row-flex">
                          <p className="gx-mr-3 gx-mb-1"><span className="">Bedrooms:</span> {data.beds}</p>
                          <p className="gx-mr-3 gx-mb-1"><span className="">Baths:</span> {data.baths}</p>
                          <p className="gx-mr-3 gx-mb-1"><span className="">Lot Size:</span> {data.acres} sqft</p>
                          <a className=" gx-text-underline gx-mb-2" href={data.link} target="_blank"> Link to Property</a>
                        </div>

                        {/*<p className="h5">Your Feedback</p>*/}


                        <div className="ant-row-flex">
                          <p className="gx-mb-1">
                            <Avatar style={{ color: '#fff', backgroundColor: this.getColorCode(data.question.qoverall) }} className="gx-size-20" alt="">{this.getNullNumber(data.question.qoverall)}</Avatar> Overall |
                          </p>
                          <p className="gx-ml-1 gx-mb-1">
                            <Avatar style={{ color: '#fff', backgroundColor: this.getColorCode(data.question.qlocation) }} className="gx-size-20" alt="">{this.getNullNumber(data.question.qlocation)}</Avatar> Location |
                          </p>
                          <p className="gx-ml-1 gx-mb-1">
                            <Avatar style={{ color: '#fff', backgroundColor: this.getColorCode(data.question.qprice) }} className="gx-size-20" alt="">{this.getNullNumber(data.question.qprice)}</Avatar> Price |
                          </p>
                          <p className="gx-ml-1 gx-mb-1">
                            Requested Tour : {(data.question.qtour ? data.question.qtour : "N/A" )}
                          </p>
                        </div>
                      </div>
                      <div className="gx-featured-content-right">
                        <div>
                          {moment(data && data.createdAt).fromNow().includes('seconds') || moment(data && data.createdAt).fromNow().includes('minute') || moment(data && data.createdAt).fromNow().includes('minutes') || moment(data && data.createdAt).fromNow().includes('hours') || moment(data && data.createdAt).fromNow().includes('hour') ? <Badge count="New" style={{ backgroundColor: '#52c41a' }} /> : ""}                          <h2 className=" gx-mb-1 gx-font-weight-medium">{formatter.format(data.price)}</h2>
                          <p className=" gx-fs-sm">{data.sqft} sqft</p>
                        </div>
                        <span>
            <a onClick={() => {
              localStorage.setItem("PropertyDetailId", data._id);
            }}>
              <Link to="listing/detail">View Details</Link>
            </a>
        </span>
                      </div>

                    </div>
                  </div>
              ) : <div className="gx-text-center">No Data Found</div>}
          </TabPane>
          <TabPane tab={
            <div>
              Disliked{' '}<Avatar style={{ color: '#fff', backgroundColor: '#515756' }} className="gx-size-20" alt="">{disliked.length}</Avatar>
            </div>
          } key="2">
            {loader ? <CircularProgress className="gx-loader-400"/> :
              disliked && disliked.length >0 ? disliked.map((data, index) =>
                // <PropertiesItemCard key={index} data={data} handlePropStatus={(value)=>this.handlePropStatus(value)} propStatusClass={propStatusClass} />
                  <div className={`gx-media gx-featured-item padding propertiesitemcard__disliked`} style={{borderRadius:'10px'}} key={index}>
                    {/*{isFeatured === true ? <Tag color="orange"><span className="gx-text-uppercase">featured</span></Tag> : null}*/}

                    <div className="gx-featured-thumb">
                      <img className="gx-rounded-lg gx-width-175" height="100" src={data.garage} alt="..."/>
                      {/*<Tag className="gx-rounded-xs gx-bg-orange gx-border-orange gx-text-white">Featured</Tag>*/}
                    </div>
                    <div className="gx-media-body gx-featured-content">
                      <div className="gx-featured-content-left">

                        {/*<Tag className="gx-rounded-xs" color="#06BB8A">FOR SALE</Tag>*/}
                        <h3 className="gx-text-white gx-mb-2">{data.address}</h3>

                        <p className="gx-text-white gx-mb-1">MLS: {data.mls}</p>

                        <div className="ant-row-flex">
                          <p className="gx-text-white gx-mr-3 gx-mb-1"><span className="gx-text-white">Bedrooms:</span> {data.beds}</p>
                          <p className="gx-text-white gx-mr-3 gx-mb-1"><span className="gx-text-white">Baths:</span> {data.baths}</p>
                          <p className="gx-text-white gx-mr-3 gx-mb-1"><span className="gx-text-white">Lot Size:</span> {data.acres} sqft</p>
                          <a className="gx-text-white gx-text-underline gx-mb-2" href={data.link} target="_blank"> Link to Property</a>
                        </div>

                        {/*<p className="h5">Your Feedback</p>*/}


                        <div className="ant-row-flex">
                          <p className="gx-mb-1">
                            <Avatar style={{ color: '#fff', backgroundColor: this.getColorCode(data.question.qoverall) }} className="gx-size-20" alt="">{this.getNullNumber(data.question.qoverall)}</Avatar> <span className="gx-text-white">Overall |</span>
                          </p>
                          <p className="gx-ml-1 gx-mb-1">
                            <Avatar style={{ color: '#fff', backgroundColor: this.getColorCode(data.question.qlocation) }} className="gx-size-20" alt="">{this.getNullNumber(data.question.qlocation)}</Avatar> <span className="gx-text-white">Location |</span>
                          </p>
                          <p className="gx-ml-1 gx-mb-1">
                            <Avatar style={{ color: '#fff', backgroundColor: this.getColorCode(data.question.qprice) }} className="gx-size-20" alt="">{this.getNullNumber(data.question.qprice)}</Avatar> <span className="gx-text-white">Price |</span>
                          </p>
                          <p className="gx-text-white gx-ml-1 gx-mb-1">
                            Requested Tour : {(data.question.qtour ? data.question.qtour : "N/A" )}
                          </p>
                        </div>
                      </div>
                      <div className="gx-featured-content-right">
                        <div>
                          {moment(data && data.createdAt).fromNow().includes('seconds') || moment(data && data.createdAt).fromNow().includes('minute') || moment(data && data.createdAt).fromNow().includes('minutes') || moment(data && data.createdAt).fromNow().includes('hours') || moment(data && data.createdAt).fromNow().includes('hour') ? <Badge count="New" style={{ backgroundColor: '#52c41a' }} /> : ""}
                          <h2 className="gx-text-white gx-mb-1 gx-font-weight-medium">{formatter.format(data.price)}</h2>
                          <p className="gx-text-white gx-fs-sm">{data.sqft} sqft</p>
                        </div>
                        <span>
            <a onClick={() => {
              localStorage.setItem("PropertyDetailId", data._id);
            }}>
              <Link to="listing/detail">View Details</Link>
            </a>
        </span>
                      </div>

                    </div>
                  </div>
              ) : <div className="gx-text-center">No Data Found</div>}
          </TabPane>
          <TabPane tab={
            <div>
              Archived{' '}<Avatar style={{ color: '#fff', backgroundColor: '#3b5998' }} className="gx-size-20" alt="">{hidden.length}</Avatar>
            </div>
          } key="3">
            {loader ? <CircularProgress className="gx-loader-400"/> :
              hidden && hidden.length >0 ? hidden.map((data, index) =>
                // <PropertiesItemCard key={index} data={data}  handlePropStatus={(value)=>this.handlePropStatus(value)} propStatusClass={propStatusClass} />

                <div className={`gx-media gx-featured-item padding propertiesitemcard__liked`}  style={{borderRadius:'10px'}} key={index}>
                    {/*{isFeatured === true ? <Tag color="orange"><span className="gx-text-uppercase">featured</span></Tag> : null}*/}

                    <div className="gx-featured-thumb">
                      <img className="gx-rounded-lg gx-width-175" height="100" src={data.garage} alt="..."/>
                      {/*<Tag className="gx-rounded-xs gx-bg-orange gx-border-orange gx-text-white">Featured</Tag>*/}
                    </div>
                    <div className="gx-media-body gx-featured-content">
                      <div className="gx-featured-content-left">

                        {/*<Tag className="gx-rounded-xs" color="#06BB8A">FOR SALE</Tag>*/}
                        <h3 className="gx-text-white gx-mb-2">{data.address}</h3>

                        <p className="gx-text-white gx-mb-1">MLS: {data.mls}</p>

                        <div className="ant-row-flex">
                          <p className="gx-text-white gx-mr-3 gx-mb-1"><span className="gx-text-white">Bedrooms:</span> {data.beds}</p>
                          <p className="gx-text-white gx-mr-3 gx-mb-1"><span className="gx-text-white">Baths:</span> {data.baths}</p>
                          <p className="gx-text-white gx-mr-3 gx-mb-1"><span className="gx-text-white">Lot Size:</span> {data.acres}sqft</p>
                          <a className="gx-text-white gx-text-underline gx-mb-2" href={data.link} target="_blank"> Link to Property</a>
                        </div>

                        {/*<p className="h5">Your Feedback</p>*/}


                        <div className="ant-row-flex">
                          <p className="gx-mb-1">
                            <Avatar style={{ color: '#fff', backgroundColor: this.getColorCode(data.question.qoverall) }} className="gx-size-20" alt="">{this.getNullNumber(data.question.qoverall)}</Avatar> <span className="gx-text-white">Overall |</span>
                          </p>
                          <p className="gx-ml-1 gx-mb-1">
                            <Avatar style={{ color: '#fff', backgroundColor: this.getColorCode(data.question.qlocation) }} className="gx-size-20" alt="">{this.getNullNumber(data.question.qlocation)}</Avatar> <span className="gx-text-white">Location |</span>
                          </p>
                          <p className="gx-ml-1 gx-mb-1">
                            <Avatar style={{ color: '#fff', backgroundColor: this.getColorCode(data.question.qprice) }} className="gx-size-20" alt="">{this.getNullNumber(data.question.qprice)}</Avatar> <span className="gx-text-white">Price |</span>
                          </p>
                          <p className="gx-text-white gx-ml-1 gx-mb-1">
                            Requested Tour : {(data.question.qtour ? data.question.qtour : "N/A" )}
                          </p>
                        </div>
                      </div>
                      <div className="gx-featured-content-right">
                        <div>
                          {moment(data && data.createdAt).fromNow().includes('seconds') || moment(data && data.createdAt).fromNow().includes('minute') || moment(data && data.createdAt).fromNow().includes('minutes') || moment(data && data.createdAt).fromNow().includes('hours') || moment(data && data.createdAt).fromNow().includes('hour') ? <Badge count="New" style={{ backgroundColor: '#52c41a' }} /> : ""}
                          <h2 className="gx-text-white gx-mb-1 gx-font-weight-medium">{formatter.format(data.price)}</h2>
                          <p className="gx-text-white gx-fs-sm">{data.sqft} sqft</p>
                        </div>
                        <span>
            <a onClick={() => {
              localStorage.setItem("PropertyDetailId", data._id);
            }}>
              <Link to="listing/detail">View Details</Link>
            </a>
        </span>
                      </div>

                    </div>
                  </div>

              ) : <div className="gx-text-center">No Data Found</div>}
          </TabPane>
        </Tabs>


      </Widget>
      </>
    );
  }
}

const mapStateToProps = ({property}) => {
  const {data, loader} = property;
  return {data, loader}
};
export default connect(mapStateToProps, {getBuyerProperties}) (PropertiesListing);


