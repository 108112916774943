import {GET_BUYER_PROPERTIES,DELETE_BUYER_PROPERTY, POST_REPLIES } from "constants/ActionTypes";
import axios from 'util/Api';
import {getBuyerPropertiesSuccess,getBuyerPropertiesError,deleteBuyerPropertySuccess,deleteBuyerPropertyError, postRepliesSuccess, postRepliesError} from "../../appRedux/actions/Property"
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import qs from "qs";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess} from "../actions";


const getBuyersPropertiesNowAsync = async () =>
  await axios.get(`property/`)
    .then((response) => {
      console.log(response.data);
      return response.data;
    }).catch(function (error) {
      if(error.response != null) {
        console.log(error.response.data);
        return error.response.data;
      }
      else {
        return error;
      }
    });

function* getBuyerPropertyNow() {

  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
    const property = yield call(getBuyersPropertiesNowAsync);
    if (property.status === 0) {

      yield put(getBuyerPropertiesError(property.message));
    }
    else if (property.status === 1) {
      yield put(getBuyerPropertiesSuccess(property.data));

      // history.push("/getBuyerProperties")
    }
    else {
      console.log("else");
      yield put(getBuyerPropertiesError(property.message));
    }
  } catch (error) {
    console.log("catch");
    yield put(getBuyerPropertiesError(error));
  }
}

// Post Replies

const postRepliesNowAsync = async (data) =>
  await axios.post('property/postreply',
    qs.stringify({
      propid: data.propertyId,
      reply: data.message,
    })
  ).then((response) => {
    console.log(response);
    return response.data;
  }).catch(function (error) {
    if(error.response != null) {
      console.log(error.response);
      return error.response.data;
    }
    else {
      return error;
    }
  });

function* postRepliesNow({payload}) {
  const { data } = payload;
  console.log(data);
  const { history } = payload;
  console.log(history)
  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
    const reply = yield call(postRepliesNowAsync,data);
    if (reply.status === 0) {
      console.log("id in generator" , data);
      yield put(postRepliesError(reply.message));
    }
    else if (reply.status === 1) {
      yield put(postRepliesSuccess(reply.data ,history));
      // console.log("Hello undefined" ,data)
      history.push("/app/comments")
    }
    else {
      console.log("else");
      yield put(postRepliesError(reply.message));
    }
  } catch (error) {
    console.log("catch");
    yield put(postRepliesError(error));
  }
}



// Delete buyer property

const deleteBuyerPropertyNowAsync = async (id) =>
  await axios.put(`property/hide/${id}`,qs.stringify({propStatus:"hidden"}))
    .then((response) => {
      console.log(response.data);
      return response.data;
    }).catch(function (error) {
      if(error.response != null) {
        console.log(error.response.data);
        return error.response.data;
      }
      else {
        return error;
      }
    });

function* deleteBuyerPropertyNow({payload}) {
  const { id } = payload;
  console.log(id)
  const idd=id.toString()
  const { history } = payload;
  console.log(history)
  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
    const property = yield call(deleteBuyerPropertyNowAsync,idd);
    if (property.status === 0) {
      console.log("if wali status zero");
      yield put(deleteBuyerPropertyError(property.message));
    }
    else if (property.status === 1) {
      yield put(deleteBuyerPropertySuccess(property.message));
      console.log("Else if wali status 1")
       // history.push("/getBuyerProperties")
    }
    else {
      console.log("else");
      yield put(deleteBuyerPropertyError(property.message));
    }
  } catch (error) {
    console.log("catch");
    yield put(deleteBuyerPropertyError(error));
  }
}

export function* watchgetBuyerproperty() {
  yield takeEvery(GET_BUYER_PROPERTIES, getBuyerPropertyNow);
}

export function* watchdeleteBuyerProperty() {
  yield takeEvery(DELETE_BUYER_PROPERTY, deleteBuyerPropertyNow);
}

export function* watchpostReplies() {
  yield takeEvery(POST_REPLIES, postRepliesNow);
}

export default function* rootSaga() {
  yield all([
    fork(watchgetBuyerproperty),
    fork(watchdeleteBuyerProperty),
    fork(watchpostReplies),
  ])

}
