import React from "react";
import UserCell from "./UserCell/index";

const ChatUserList = ({chatUsers, selectedSectionId, onSelectUser}) => {
  // console.log("Chat Users",chatUsers)
  // console.log(selectedSectionId)
  // console.log(onSelectUser)
  // if (chatUsers.length === 0)
  // {
  //   return <div className="gx-p-5 gx-text-center">No Records Found</div>
  // }

  const newList = chatUsers.sort((a,b) => {return  b.replies[b.replies.length - 1].time > a.replies[a.replies.length - 1].time ? 1 : -1 })


  return (
    <div className="gx-chat-user">
      {newList.map((chat, index) =>
        <UserCell key={index} chat={chat} selectedSectionId={selectedSectionId} onSelectUser={onSelectUser}/>
      )}
    </div>
  )
};

export default ChatUserList;
