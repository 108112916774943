import React, {useEffect} from "react";
import {Avatar, Row, Tag, Tooltip} from "antd";
import {Link} from "react-router-dom";
import '../dashboard/propertycss/PropertiesItemCard.css';

function getColor(overall) {
  if(overall <= 2){
    return <i className="icon icon-circle gx-fs-xxs" style={{color:'#FF0700'}}/>
  }else if(overall <= 4 && overall >= 3){
    return <i className="icon icon-circle gx-fs-xxs" style={{color:'#ffa334'}}/>
  }else if(overall <= 6 && overall >= 5){
    return <i className="icon icon-circle gx-fs-xxs" style={{color:'#ffce1f'}}/>
  }else if(overall <= 8 && overall >= 7){
    return  <i className="icon icon-circle gx-fs-xxs" style={{color:'#fef100'}}/>
  } else if(overall <= 10 && overall >= 9){
    return <i style={{color:'#80D726'}} className="icon icon-circle gx-fs-xxs"/>


  }
}

function getColorCode(overall) {
  if(overall == null){
    return '#515756';
  }else if(overall <= 2){
    return '#FF0700';
  }else if(overall <= 4 && overall >= 3){
    return '#FF7800';
  }else if(overall <= 6 && overall >= 5){
    return '#ffce1f';
  }else if(overall <= 8 && overall >= 7){
    return '#FEF100';
  } else if(overall <= 10 && overall >= 9){
    return '#80D726';
  }
}
function getNullNumber(overall) {
  if(overall == null){
    return 'x';
  }else if(overall >= 0){
    return overall;
  }
}

const PropertiesItemCard = (props) => {
  const {_id, mls, price, address ,city ,state ,zip ,built ,sqft ,stories ,beds ,baths ,garage ,parking ,acres ,schooldist ,tax ,hoa ,listdate ,adom ,agent_name ,agent_phone ,agent_email, question,link,propStatus} = props.data;

 useEffect(() =>{
   console.log("useEffect updation occured")
   // if (propStatus)
   // {
   //   let propStatusValue;
   //   if (propStatus === "liked")
   //   {
   //     propStatusValue = "propertiesitemcard__liked"
   //     props.handlePropStatus(propStatusValue)
   //   }
   //   if (propStatus === "disliked")
   //   {
   //     propStatusValue = "propertiesitemcard__disliked"
   //     props.handlePropStatus(propStatusValue)
   //   }
   //   else
   //   {
   //     propStatusValue = "propertiesitemcard__hidden"
   //     props.handlePropStatus(propStatusValue)
   //   }
   // }
 },[props.propStatusClass])

  return (

    <div className={`gx-media gx-featured-item padding ${props.propStatusClass}`}>
      {/*{isFeatured === true ? <Tag color="orange"><span className="gx-text-uppercase">featured</span></Tag> : null}*/}

      <div className="gx-featured-thumb">
        <img className="gx-rounded-lg gx-width-175" src={garage} alt="..."/>
        {/*<Tag className="gx-rounded-xs gx-bg-orange gx-border-orange gx-text-white">Featured</Tag>*/}
      </div>
      <div className="gx-media-body gx-featured-content">
        <div className="gx-featured-content-left">

          {/*<Tag className="gx-rounded-xs" color="#06BB8A">FOR SALE</Tag>*/}
          <h3 className="gx-mb-2">{address}</h3>

          <p className=" gx-mb-1">MLS: {mls}</p>

          <div className="ant-row-flex">
            <p className="gx-mr-3 gx-mb-1"><span className="">Bedrooms:</span> {beds}</p>
            <p className="gx-mr-3 gx-mb-1"><span className="">Baths:</span> {baths}</p>
            <p className="gx-mr-3 gx-mb-1"><span className="">Lot Size:</span> {acres}</p>
            <a className=" gx-text-underline gx-mb-2" href={link} target="_blank"> Link to Property</a>
          </div>

          {/*<p className="h5">Your Feedback</p>*/}


          <div className="ant-row-flex">
            <p className="gx-mb-1">
              <Avatar style={{ color: '#fff', backgroundColor: getColorCode(question.qoverall) }} className="gx-size-20" alt="">{getNullNumber(question.qoverall)}</Avatar> Overall |
            </p>
            <p className="gx-ml-1 gx-mb-1">
              <Avatar style={{ color: '#fff', backgroundColor: getColorCode(question.qlocation) }} className="gx-size-20" alt="">{getNullNumber(question.qlocation)}</Avatar> Location |
            </p>
            <p className="gx-ml-1 gx-mb-1">
              <Avatar style={{ color: '#fff', backgroundColor: getColorCode(question.qprice) }} className="gx-size-20" alt="">{getNullNumber(question.qprice)}</Avatar> Price |
            </p>
            <p className="gx-ml-1 gx-mb-1">
                Requested Tour : {(question.qtour ? question.qtour : "N/A" )}
            </p>
          </div>
        </div>
        <div className="gx-featured-content-right">
          <div>
            <h2 className=" gx-mb-1 gx-font-weight-medium">{price}</h2>
            <p className=" gx-fs-sm">{sqft} sqft</p>
          </div>
          <span>
            <a onClick={() => {
              localStorage.setItem("PropertyDetailId", _id);
            }}>
              <Link to="listing/detail">View Details</Link>
            </a>
        </span>
        </div>

      </div>
    </div>
  );
}

export default PropertiesItemCard;
