import {applyMiddleware, compose, createStore} from "redux";
import reducers from "../reducers/index";
import {routerMiddleware} from "react-router-redux";
import createHistory from "history/createBrowserHistory";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/index";
import thunk from 'redux-thunk';

const history = createHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk,sagaMiddleware, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('buyer_state',serializedState);
  }
  catch (e) {
    console.log(e);
  }

}
function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('buyer_state');
    if (serializedState === null) return undefined
    return JSON.parse(serializedState);

  }
  catch (e) {
    console.log(e);
  }
}
const persistedState = loadFromLocalStorage();

export default function configureStore(initialState) {
  const store = createStore(reducers, initialState,
    applyMiddleware(...middlewares));
  store.subscribe(()=>saveToLocalStorage(store.getState()))
  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
export {history};
