import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_AGENT,
  CHANGE_AGENT_SUCCESS,
  OTP_VERIFIED,
  OTP_VERIFIED_SUCCESS,
  OTP_VERIFIED_ERROR,
  OTP_RESEND,

  FORGET_PASSWORD_EMAIL,
  FORGET_PASSWORD_EMAIL_SUCCESS,
  FORGET_PASSWORD_VERIFICATION,
  FORGET_PASSWORD_VERIFICATION_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,

  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS
} from "constants/ActionTypes";

export const userSignUp = (user, history) => {
  return {
    type: SIGNUP_USER,
    payload: {user, history}
  };
};
export const userSignIn = (user, history) => {
  return {
    type: SIGNIN_USER,
    payload: { user, history }
  };
};

export const getDashboard = () => {
  return {
    type: GET_DASHBOARD
  };
};

export const getDashboardSuccess = (authUser) => {
  return {
    type: GET_DASHBOARD_SUCCESS,
    payload: authUser
  };
};

export const userChangePassword = (data, history) => {
  return {
    type: CHANGE_PASSWORD,
    payload: {data, history}
  };
};

export const userChangeAgent = (data, history) => {
  return {
    type: CHANGE_AGENT,
    payload: {data, history}
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};
export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  }

};

export const userChangePasswordSuccess = (data) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: data
  }
};

export const userChangeAgentSuccess = (data) => {
  return {
    type: CHANGE_AGENT_SUCCESS,
    payload: data
  }
};

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};


export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER
  };
};
export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER
  };
};
export const userFacebookSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER
  };
};
export const userTwitterSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER
  };
};
export const userGithubSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export const otpVerified = (user, history) => {
  return {
    type: OTP_VERIFIED,
    payload: { user, history }
  };
};

export const otpUserSuccess = (message1) => {
  return {
    type: OTP_VERIFIED_SUCCESS,
    payload: message1
  };
};

export const otpUserError = (message1) => ({
  type: OTP_VERIFIED_ERROR,
  payload:  message1

});

export const otpResend = (user, history) => {
  return {
    type: OTP_RESEND,
    payload: {user, history}
  };
};

// Forget Password Email Sending
export const forgetPasswordEmail = (user, history) => {
  return {
    type: FORGET_PASSWORD_EMAIL,
    payload: { user, history }
  };
};

export const forgetPasswordEmailSuccess = (authUser, history) => {
  return {
    type: FORGET_PASSWORD_EMAIL_SUCCESS,
    payload: {authUser, history}
  }
};

// Forget Password Verification
export const forgetPasswordVerification = (user, history) => {
  return {
    type: FORGET_PASSWORD_VERIFICATION,
    payload: { user, history }
  };
};

export const forgetPasswordVerificationSuccess = (authUser, history) => {
  return {
    type: FORGET_PASSWORD_VERIFICATION_SUCCESS,
    payload: {authUser, history}
  }
};

// Forget Password Reset Password
export const resetPassword = (ChangePassword, history) => {
  return {
    type: RESET_PASSWORD,
    payload: {ChangePassword, history}
  }
};

export const resetPasswordSuccess = (ChangePassword, history) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: {ChangePassword, history}
  }
};
