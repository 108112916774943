import React from "react";
import {Button, Card, Table, Tooltip, Modal, Skeleton, Input, Icon, Row, Col, Avatar} from "antd";
import {
  getBuyerProperties,
  deleteBuyerProperty
} from "../../../appRedux/actions";
import {connect} from "react-redux";
import Highlighter from "react-highlight-words";
import {Link} from "react-router-dom";
import {AiOutlineLeft} from "react-icons/ai";
import ReactDragListView from "react-drag-listview";
import moment from 'moment';
import {Widget} from "react-chat-widget";
import 'react-chat-widget/lib/styles.css';
import Chat from '../../../components/chat/index'
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
const expandedRowRender = user => <div>
  <h3>Agent Detail</h3>
  <p> Name: {user.agent.firstName} {user.agent.lastName}</p>
  <p> Email: {user.agent.email}</p>


</div>;

function getColorCode(overall) {
  if(overall === ""){
    return '#515756';
  }else if(overall <= 2){
    return '#FF0700';
  }else if(overall <= 4 && overall >= 3){
    return '#FF7800';
  }else if(overall <= 6 && overall >= 5){
    return '#ffce1f';
  }else if(overall <= 8 && overall >= 7){
    return '#FEF100';
  } else if(overall <= 10 && overall >= 9){
    return '#80D726';
  }
}
function getNullNumber(overall) {
  if(overall === ""){
    return 'x';
  }else if(overall >= 0){
    return overall;
  }
}

class GetBuyerProperties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id : '',
      modal: false,
      data: null,
      imageModalVisible: false,
      CommentModalVisible: false,
      modalOpen: false,
      garage: "",
      expandedRowRender,
      columns2: localStorage.getItem("columns"),

      columns: [
        {
          title: 'Last modified',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          align: 'center',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
          render: (lastModified) => (
            <div>{lastModified ? moment(lastModified).fromNow() : ""}</div>
          )
        },
        {
          title: "Thumbnail",
          dataIndex: "garage",
          key: 'garage',
          render: (theImageURL) => (
            <span>
            <img className="gx-img-thumbnail" src={theImageURL} alt="realtism" onClick={()=>this.setImageModal(theImageURL)}
                 style={{height: '60px', 'border-radius': '10px'}}/>
            <Modal
              centered
              onCancel={()=>this.setCloseImageModal()}
              title="Image"
              style={{top: 20}}
              visible={this.state.imageModalVisible}
              footer={null}
            >

                <img className="gx-img-fluid-full" src={this.state.garage} alt="realtism"/>

            </Modal>
          </span>

          )
        },

        {
          title: 'MLS',
          dataIndex: 'mls',
          key: 'mls',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.mls.localeCompare(b.mls),
          ...this.getColumnSearchProps('mls', ''),

        },
        {
          title: 'DOM',
          dataIndex: 'DOMdate',
          key: 'DOMdate',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.DOMdate.localeCompare(b.DOMdate),
          ...this.getColumnSearchProps('DOMdate', ''),
          render: (DOMdate) => (
            <div>{DOMdate ? moment(new Date()).diff(DOMdate,"days")+1 : ""} day(s) ago</div>
          )
        },
        {
          title: 'Status',
          dataIndex: 'mStatus',
          key: 'mStatus',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.mStatus.localeCompare(b.mStatus),
          ...this.getColumnSearchProps('status', ''),
          render: (status) => (
            <div><p className="gx-text-capitalize">{status}</p></div>
          )
        },
        {
          title: 'Price',
          dataIndex: 'price',
          key: 'price',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.price.localeCompare(b.price),
          ...this.getColumnSearchProps('price', '(000,00)'),
          render: (price) => (
            <div>   {formatter.format(price)}</div>
          )
        }, {
          title: 'Address',
          dataIndex: 'address',
          key: 'address',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.address.localeCompare(b.address),
          ...this.getColumnSearchProps('address', ''),
        },
        {
          title: 'Requested Tour',
          dataIndex: 'question.qtour',
          key: 'question.qtour',
          align: 'center',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.question.qtour.localeCompare(b.question.qtour),
          // ...this.getColumnSearchProps('question.qtour', ''),
          render: (tour) => (
            <div>   {tour ? tour : "N/A"}</div>
          )
        },
        {
          title: 'Overall',
          dataIndex: 'question.qoverall',
          key: 'question.qoverall',
          align: 'center',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.question.qoverall.localeCompare(b.question.qoverall),
          // ...this.getColumnSearchProps('question.qoverall', ''),
          render: (overall) => (
            <Avatar style={{color: '#fff', backgroundColor: getColorCode(overall)}} className="gx-size-20"
                    alt="">{getNullNumber(overall)}</Avatar>
          )
        }, {
          title: 'Price',
          dataIndex: 'question.qprice',
          key: 'question.qprice',
          align: 'center',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.question.qprice.localeCompare(b.question.qprice),
          // ...this.getColumnSearchProps('question.qprice', ''),
          render: (overall) => (
            <Avatar style={{color: '#fff', backgroundColor: getColorCode(overall)}} className="gx-size-20"
                    alt="">{getNullNumber(overall)}</Avatar>
          )
        }, {
          title: 'Location',
          dataIndex: 'question.qlocation',
          key: 'question.qlocation',
          align: 'center',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.question.qlocation.localeCompare(b.question.qlocation),
          // ...this.getColumnSearchProps('question.qlocation', ''),
          render: (overall) => (
            <Avatar style={{color: '#fff', backgroundColor: getColorCode(overall)}} className="gx-size-20"
                    alt="">{getNullNumber(overall)}</Avatar>
          )
         },
        // {
        //   title: 'Comments',
        //   dataIndex: '_id',
        //   key: '_id',
        //   align: 'center',
        //   render: (id) => (
        //     <span>
        //     <a
        //        onClick={()=>this.commentsModal(id)}
        //     >
        //     <i className='icon icon-chat'/>
        //     {/*  <Tooltip title={comments ? comments : "No comments..."}>*/}
        //     {/*   */}
        //     {/*</Tooltip>*/}
        //
        //     </a>
        //
        // </span>
        //   )
        // },
        {
          title: 'Comments',
          dataIndex: 'question.qcomments',
          key: 'question.qcomments',
          align: 'center',
          render: (comments) => (
            <span>
            {/*<a
              onClick={()=>this.commentsModal(id)}
            >
            <i className='icon icon-chat'/>
            </a>*/}
              <Tooltip title={comments ? comments : "No comments..."}>
                <i className='icon icon-chat'/>
              </Tooltip>



        </span>
          )
        },
        {
          title: 'Details',
          dataIndex: '_id',
          key : 'details',
          align: 'center',
          render: (id) => (
            <span>
            <a onClick={() => {
              this.propertyDetail(id)
            }}>
              <Link to="listing/detail">
              <Tooltip title="Open Property">
                <i className='icon icon-exclamation'/>
            </Tooltip>
              </Link></a>
        </span>
          )
        }, {
          title: 'Link',
          dataIndex: 'link',
          key: 'link',
          align: 'center',
          render: (link) => (
            <span>
            <a href={link} target="_blank"><Tooltip title="Link to Property">
                <i className='icon icon-link'/>
            </Tooltip></a>
        </span>
          )
        },
        {
          title: 'Delete',
          dataIndex: '_id',
          key: '_id',
          align: 'center',
          render: (id) => (
            <span>
            <a onClick={() => {
              this.deleteProperty(id)
            }}>

              <Tooltip title="Delete property">
               <Icon type="delete" />
            </Tooltip>
             </a>

        </span>
          )
        }

      ],
      columns3: []

    };

    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const columns = [...that.state.columns];
        const item = columns.splice(fromIndex, 1)[0];
        columns.splice(toIndex, 0, item);
        that.setState({
          columns
        });
        localStorage.setItem("columns", columns);
      },
      nodeSelector: "th"
    };
  }

  state = {
    filteredInfo: null,
    sortedInfo: null,

  };

  setImageModal=(data) =>{
    this.setState({
      imageModalVisible: true,
      garage: data
    });
  }

  setCloseImageModal= e => {
    this.setState({
      imageModalVisible: false,
    });
  }

  // commentsModal = (id) => {
  //   // console.log("Comments Property ID", key)
  //   // localStorage.setItem("propertyID",key)
  //   this.setState({id : id})
  //   this.toggleModalUser()
  // }




  toggleModalUser = () => {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
    // localStorage.removeItem("propertyID")
  }



  setCommentModal(CommentModalVisible) {
    console.log("Chat clicked", {CommentModalVisible});
    this.setState({CommentModalVisible});
  }

  setCloseCommentModal() {
    console.log("chat clicked");
    Modal.destroyAll();
  }

  componentDidMount() {
    this.props.getBuyerProperties();
  }

  componentDidUpdate() {

  }

  propertyDetail = (info) => {
   // console.log("this is has to be set", info)

    this.setState({
      data: info
    })
    this.toggleModal();
     localStorage.setItem("PropertyDetailId", info)
  }

  deleteProperty = (id) => {
   console.log("this is has to be set", id)
    // localStorage.setItem("PropertyDeleteId", info)
    this.props.deleteBuyerProperty(id, this.props.history);
    this.props.getBuyerProperties();
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({filteredInfo: null});
  };
  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });


  };
  setAgeSort = () => {
    this.setState({
      sortedInfo: {
        order: 'descend',
        columnKey: 'age',
      },
    });
  };
  handleExpandChange = (enable) => {
    this.setState({expandedRowRender: enable ? expandedRowRender : undefined});
  };
  getColumnSearchProps = (dataIndex, data) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex} ${data}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: 'block'}}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{color: filtered ? '#1890ff' : undefined}}/>
    ),
    onFilter: (value, record) =>
      record[dataIndex]

        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        )
        : (
          text
        ),
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({searchText: ''});
  };


  render() {
    const mystyle = {
      background: "#ff0700",
      borderRadius: "50%"
    };
    let {sortedInfo, filteredInfo} = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    let filteredProperties;
    if (this.props.data)
    {
      filteredProperties = this.props.data.filter((data) => data.propStatus !== "hidden")
    }

    return (

      <div>
        <Modal
          centered
          onCancel={() => this.toggleModalUser()}
          title={null}
          // style={{top: 20}}
          visible={this.state.modalOpen}
          footer={null}
          width={800}
        >

          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card>
              <Chat id={this.state.id} />
            </Card>

          </Col>

        </Modal>
        <Button type="primary">
          <Link to='dashboard' style={{color: 'white'}}>
          <Row>
            <Col span={1}>
              <AiOutlineLeft style={{marginTop: 10, textAlign: 'center'}}/>
            </Col>
            <Col>
             Back
            </Col>
          </Row>
          </Link>
        </Button>
        <Card title="Property Listings">
          <ReactDragListView.DragColumn {...this.dragProps}>
            <Table className="gx-table-responsive" columns={this.state.columns} dataSource={filteredProperties}
                   onChange={this.handleChange} onRow={(r) => ({
              onClick: () => (
                this.propertyDetail(r._id)
              )
            })}/>
          </ReactDragListView.DragColumn>

        </Card>

      </div>
    );
  }
}

const mapStateToProps = ({property}) => {
  const {data, buyerProperties, loader} = property;
  return {data, buyerProperties, loader}
};
export default connect(mapStateToProps, {getBuyerProperties, deleteBuyerProperty})(GetBuyerProperties);

