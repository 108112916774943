import React, {Component, useEffect} from "react";
import {Avatar, Button, Drawer, Input, Tabs} from "antd";
import CustomScrollbars from "util/CustomScrollbars";
import Moment from "moment";
import './Chat.css'

import {
getDashboard,
  getBuyerProperties,
  postReplies
} from "../../appRedux/actions";

import ChatUserList from "./ChatUserList/index";
import conversationList from "./data/conversationList";
import Conversation from "./Conversation/index";
import users from "./data/chatUsers";
import ContactList from "./ContactList/index";
import IntlMessages from "../../util/IntlMessages";
import SearchBox from "../SearchBox";
import CircularProgress from "../../components/CircularProgress/index";
import {connect} from "react-redux";

const TabPane = Tabs.TabPane;

class Chat extends Component {

  filterContact = (userName) => {
    if (userName === '') {
      return users.filter(user => !user.recent);
    }
    return users.filter((user) =>
      !user.recent && user.name.toLowerCase().indexOf(userName.toLowerCase()) > -1
    );
  };
  filterUsers = (userName) => {
    if (userName === '') {
      return users.filter(user => user.recent);
    }
    return users.filter((user) =>
      user.recent && user.name.toLowerCase().indexOf(userName.toLowerCase()) > -1
    );
  };
  Communication = () => {
    const {message, selectedUser, conversation, selectedSectionId} = this.state;
    // const {conversationData} = conversation;
    // const {data} = this.props.data;
    return <div className="gx-chat-main">
      <div className="gx-chat-main-header">
        <span className="gx-d-block gx-d-lg-none gx-chat-btn"><i className="gx-icon-btn icon icon-chat"
                                                                 onClick={this.onToggleDrawer.bind(this)}/></span>
        <div className="gx-chat-main-header-info">

          <div className="gx-chat-avatar gx-mr-2">
            <div className="gx-status-pos">
              <Avatar src={selectedUser.garage}
                      className="gx-rounded-circle gx-size-40"
                      alt=""/>

              {/*<span className={`gx-status gx-${selectedUser.status}`}/>*/}
            </div>
          </div>

          <div className="gx-chat-contact-name">
            {selectedUser.address}
          </div>
        </div>
      </div>

      <CustomScrollbars className="gx-chat-list-scroll scroll-color">
        <Conversation conversationData={
          // [
          //   {
          //     'type': 'received',
          //     'message': 'All the Lorem Ipsum generators on the',
          //     'sentAt': '3:12:45 PM',
          //   },
          // ]
          this.props.data && this.props.data && this.props.data.filter((data) => data._id === selectedUser._id)
        }
                      selectedSectionId = {selectedSectionId}
                      selectedUser={selectedUser}
                      /*authUser = {this.props.authUser && this.props.authUser._id}*/
        />


      </CustomScrollbars>

      <div className="gx-chat-main-footer">
        <div className="gx-flex-row gx-align-items-center" style={{maxHeight: 51}}>
          <div className="gx-col">
            <div className="gx-form-group">
                            <input
                              id="required" className="gx-border-0 ant-input gx-chat-textarea"
                              onKeyUp={this._handleKeyPress.bind(this)}
                              onChange={this.updateMessageValue.bind(this)}
                              value={message}
                              placeholder="Type and hit enter to send message"
                            />
            </div>
          </div>
          <i className="gx-icon-btn icon icon-sent" onClick={this.submitComment.bind(this)}/>
        </div>
      </div>
    </div>
  };

  AppUsersInfo = () => {
    return <div className="gx-chat-sidenav-main">
      <div className="gx-bg-grey-light gx-chat-sidenav-header">

        <div className="gx-chat-user-hd gx-mb-0">
          <i className="gx-icon-btn icon icon-arrow-left" onClick={() => {
            this.setState({userState: 1});
          }}/>

        </div>
        {/*<div className="gx-chat-user gx-chat-user-center">*/}
        {/*  <div className="gx-chat-avatar gx-mx-auto">*/}
        {/*    <Avatar src='https://via.placeholder.com/150x150'*/}
        {/*            className="gx-size-60" alt="John Doe"/>*/}
        {/*  </div>*/}

        {/*  <div className="gx-user-name h4 gx-my-2">Robert Johnson</div>*/}

        {/*</div>*/}
      </div>
      <div className="gx-chat-sidenav-content">

        {/*<CustomScrollbars className="gx-chat-sidenav-scroll">*/}
        {/*  <div className="gx-p-4">*/}
        {/*    <form>*/}
        {/*      <div className="gx-form-group gx-mt-4">*/}
        {/*        <label>Mood</label>*/}

        {/*        <Input*/}
        {/*          fullWidth*/}
        {/*          id="exampleTextarea"*/}
        {/*          multiline*/}
        {/*          rows={3}*/}
        {/*          onKeyUp={this._handleKeyPress.bind(this)}*/}
        {/*          onChange={this.updateMessageValue.bind(this)}*/}
        {/*          defaultValue="it's a status....not your diary..."*/}
        {/*          placeholder="Status"*/}
        {/*          margin="none"/>*/}

        {/*      </div>*/}
        {/*    </form>*/}
        {/*  </div>*/}
        {/*</CustomScrollbars>*/}

      </div>
    </div>
  };
  ChatUsers = () => {
    console.log("Chat Users", this.props.data)
    return <div className="gx-chat-sidenav-main">

      <div className="gx-chat-sidenav-header scroll-color">

        <div className="gx-chat-user-hd">

          <div className="gx-chat-avatar gx-mr-3" onClick={() => {
            this.setState({
              userState: 2
            });
          }}>
            <div className="gx-status-pos">
              {/*<Avatar id="avatar-button" src='https://via.placeholder.com/150x150'*/}
              {/*        className="gx-size-50"*/}
              {/*        alt=""/>*/}
              <Avatar style={{ color: '#fff', backgroundColor: '#7c7e7d' }}
                      className="gx-size-50" alt="">{this.props.authUser && this.props.authUser.agent && this.props.authUser.agent.firstName && this.props.authUser.agent.firstName.charAt(0)}</Avatar>

              {/*<span className="gx-status gx-online"/>*/}
            </div>
          </div>

          <div className="gx-module-user-info gx-flex-column gx-justify-content-center">
            <div className="gx-module-title">
              <h5 className="gx-mb-0">{this.props.authUser && this.props.authUser.agent && this.props.authUser.agent.firstName} {this.props.authUser && this.props.authUser.agent && this.props.authUser.agent.lastName}</h5>
            </div>
            {/*<div className="gx-module-user-detail">*/}
            {/*  <span className="gx-text-grey gx-link">robert@example.com</span>*/}
            {/*</div>*/}
          </div>
        </div>

        {/*<div className="gx-chat-search-wrapper">*/}

        {/*  <SearchBox styleName="gx-chat-search-bar gx-lt-icon-search-bar-lg"*/}
        {/*             placeholder="Search or start new chat"*/}
        {/*             onChange={this.updateSearchChatUser.bind(this)}*/}
        {/*             value={this.state.searchChatUser}/>*/}

        {/*</div>*/}
      </div>

      <div className="gx-chat-sidenav-content">
        {/*<AppBar position="static" className="no-shadow chat-tabs-header">*/}
        <Tabs className="gx-tabs-half" defaultActiveKey="1">
          <TabPane  tab={<span>Recent</span>} key="1">
            <CustomScrollbars className="gx-chat-sidenav-scroll-tab-1" >
              { this.props.data && this.props.data.length === 0 ?
                <div className="gx-p-5 gx-text-center">{this.state.userNotFound}</div>
                :
                <ChatUserList chatUsers={this.props.data ? this.props.data.filter((data) => data.replies.length > 0) : []}
                              selectedSectionId={this.state.selectedSectionId}
                              onSelectUser={this.onSelectUser.bind(this)}/>
              }
            </CustomScrollbars>
          </TabPane>
          <TabPane  tab={<span>All Properties</span>} key="2">
            <CustomScrollbars className="gx-chat-sidenav-scroll-tab-2">
              {
                this.props.data && this.props.data.length === 0 ?
                  <div className="gx-p-5 gx-text-center">{this.state.userNotFound}</div>
                  :
                  <ContactList contactList={this.props.data && this.props.data}
                               selectedSectionId={this.state.selectedSectionId}
                               onSelectUser={this.onSelectUser.bind(this)}/>
              }
            </CustomScrollbars>
          </TabPane>
        </Tabs>


      </div>
    </div>
  };
  _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.submitComment();
    }
  };

  handleChange = (event, value) => {
    this.setState({selectedTabIndex: value});
  };

  handleChangeIndex = index => {
    this.setState({selectedTabIndex: index});
  };
  onSelectUser = async (user) => {
    this.setState({
      loader: true,
      selectedSectionId: user._id,
      drawerState: this.props.drawerState,
      selectedUser: user,
      conversation: this.props.data.find((data) => data._id === user._id)
    });
      this.setState({loader: false});
    this.props.getBuyerProperties()
  };
  showCommunication = () => {
    return (
      <div className="gx-chat-box">
        {this.state.selectedUser === null ?
          <div className="gx-comment-box">
            <div className="gx-fs-80"><i className="icon icon-chat gx-text-muted"/></div>
            <h1 className="gx-text-muted">{<IntlMessages id="chat.selectUserChat"/>}</h1>
            <Button className="gx-d-block gx-d-lg-none" type="primary"
                    onClick={this.onToggleDrawer.bind(this)}>{<IntlMessages
              id="chat.selectContactChat"/>}</Button>

          </div>
          : this.Communication()}
      </div>)
  };

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      userNotFound: 'empty',
      drawerState: false,
      selectedSectionId: '',
      selectedTabIndex: 1,
      userState: 1,
      searchChatUser: '',
      contactList: users.filter((user) => !user.recent),
      selectedUser: null,
      message: '',
      chatUsers: users.filter((user) => user.recent),
      conversationList: null,
      conversation: null
    }
  }

  componentDidMount() {
     this.props.getDashboard()
    this.props.getBuyerProperties()
  }

  async submitComment() {
    if (this.state.message !== '') {
      // const updatedConversation = this.state.conversation.conversationData.concat({
      //
      //   message: this.state.message,
      //   // 'sentAt': Moment().format('hh:mm:ss A'),
      // });
      // this.setState({
      //   conversation: {
      //     ...this.state.conversation, conversation: updatedConversation
      //
      //   },
      //   message: '',
      //   conversationList: this.state.conversationList.map(conversationData => {
      //     if (conversationData.id === this.state.conversation.id) {
      //       return {...this.state.conversation, conversationData: updatedConversation};
      //     } else {
      //       return conversationData;
      //     }
      //   })
      // });
      const updatedConversation = await {
        propertyId : this.state.selectedSectionId,
        message : this.state.message
      }


      // console.log("Data to be submitted", updatedConversation)
      await this.props.postReplies(updatedConversation,this.props.history)
      await this.setState({message : ''})
    }
    this.props.getBuyerProperties();
    this.setState({message : ''});
  }

  updateMessageValue(evt) {
    this.setState({
      message: evt.target.value
    });
  }

  updateSearchChatUser(evt) {
    this.setState({
      searchChatUser: evt.target.value,
      contactList: this.filterContact(evt.target.value),
      chatUsers: this.filterUsers(evt.target.value)
    });
  }

  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState
    });
  }

  render() {
    const {loader, userState, drawerState} = this.state;
    return (
        <div className="gx-main-content">
          <div className="gx-app-module gx-chat-module">
            <div className="gx-chat-module-box">
              <div className="gx-d-block gx-d-lg-none">
                <Drawer
                  placement="left"
                  closable={false}
                  visible={drawerState}
                  onClose={this.onToggleDrawer.bind(this)}>
                  {userState === 1 ? this.ChatUsers() : this.AppUsersInfo()}
                </Drawer>
              </div>
              <div className="gx-chat-sidenav gx-d-none gx-d-lg-flex">
                {userState === 1 ? this.ChatUsers() : this.AppUsersInfo()}
              </div>
              {loader ?
                <div className="gx-loader-view">
                  <CircularProgress/>
                </div> : this.showCommunication()
              }
            </div>
          </div>
        </div>

    )
  }
}


const mapStateToProps = ({property,auth}) => {
  const {data, buyerProperties, loader} = property;
  const {authUser} = auth;
  return {data, buyerProperties, authUser, loader}
};
export default connect(mapStateToProps, {getBuyerProperties, getDashboard,postReplies})(Chat);
