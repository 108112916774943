import React from 'react';
import {Form, Input,Select, Button} from 'antd';


const FormItem = Form.Item;
const { Option } = Select;
const SubForm2 = (props) =>{

  const { values, handleChange,handleSelect, form: {getFieldDecorator} } = props;
  const Continue = e => {
    e.preventDefault();
    props.nextStep(props.history);
  };
  const Back = e => {
    e.preventDefault();
    props.prevStep();
  };

  return(
    <span className="gx-text-center gx-mt-4">
           <FormItem label="Minimum Budget" hasFeedback>
             {getFieldDecorator('budgetMin', {
               initialValue: values && values.budgetMin ? values.budgetMin : undefined,
               rules: [{

                 message: 'Please enter your minimum budget',
               }
               ],
             })(
               <Input onChange={handleChange("budgetMin")} placeholder="Enter minimum budget in $"/>
             )}
           </FormItem>

       <FormItem label="Maximum Budget" hasFeedback>
             {getFieldDecorator('budgetMax', {
               initialValue: values && values.budgetMax ? values.budgetMax : undefined,
               rules: [{

                 message: 'Please enter your maximum budget',
               }
               ],
             })(
               <Input onChange={handleChange("budgetMax")} placeholder="Enter maximum budget in $"/>
             )}
           </FormItem>


      <Form.Item label="Payment Plan" hasFeedback>
          {getFieldDecorator('paymentPlan', {
            initialValue: values && values.paymentPlan ? values.paymentPlan : undefined,
            rules: [{  message: 'Please, select your payment plan!' }],
          })(
            <Select
              placeholder="Do you plan on obtaining a mortgage or paying in cash?"
              onChange={handleSelect("paymentPlan")}
            >
              <Option value="Financing">Financing</Option>
              <Option value="Paying in Cash">Paying in Cash</Option>
            </Select>
          )}
        </Form.Item>

       <Form.Item label="Property Use" hasFeedback>
          {getFieldDecorator('propertyUse', {
            initialValue: values && values.propertyUse ? values.propertyUse : undefined,
            rules: [{  message: 'Select Property Use!' }],
          })(
            <Select
              placeholder="What is the intended use for the property?"
              onChange={handleSelect("propertyUse")}
            >
              <Option value="Primary Residence">Primary Residence</Option>
              <Option value="Secondary/Vacation Residence">Secondary/Vacation Residence</Option>
              <Option value="Investment Property">Investment Property</Option>
            </Select>
          )}
        </Form.Item>

          <FormItem className="gx-text-center">
              <Button
                // disabled={
                //   (!(props.form.isFieldTouched('budgetMin') && props.form.isFieldTouched('budgetMax') && props.form.isFieldTouched('paymentPlan') && props.form.isFieldTouched('propertyUse')
                //     && !(props.form.getFieldError('budgetMin')) && !(props.form.getFieldError('budgetMax'))  && !(props.form.getFieldError('paymentPlan'))  && !(props.form.getFieldError('propertyUse'))    ))
                //   }
                onClick={Continue}
                type="primary">
                Next
              </Button>
             <Button
               onClick={Back}
               type="warning"
               htmlType="submit">
            Back
          </Button>
            </FormItem>



        </span>
  )
}

export default SubForm2;



