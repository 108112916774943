import {GET_ALL_PROFILE, STORE_PROFILE } from "constants/ActionTypes";
import axios from 'util/Api';
import {getAllProfile,getAllProfileSuccess,getAllProfileError,storeProfile,storeProfileSuccess,storeProfileError} from "../../appRedux/actions/Profile"
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {showAuthMessage, userSignInSuccess} from "../actions";
import qs from "qs";


const getallProfileNowAsync = async () =>
  await axios.get('buyerprofile/')
    .then((response) => {
      console.log(response);
      return response.data;
    }).catch(function (error) {
      if(error.response != null) {
        console.log(error.response.data);
        return error.response.data;
      }

      else {
        return error;
      }
    });
function* getAllProfileNow(){
  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
    const profile = yield call(getallProfileNowAsync);
    if (profile.status === 0) {
      yield put(getAllProfileError(profile.message));
    } else if (profile.status === 1) {
      console.log("status 1");
      yield put(getAllProfileSuccess(profile));
    }
    else {
      console.log("else");
      yield put(getAllProfileError(profile.message));
    }
  } catch (error) {
    console.log("catch");
    yield put(getAllProfileError(error));
  }

}

export function* watchgetallprofile() {
  yield takeEvery(GET_ALL_PROFILE, getAllProfileNow);
}


const storeProfileNowAsync = async (phone,age,income,style,buyingExperience,adults,children,pets,budgetMin,budgetMax,paymentPlan,propertyUse,lookingFor,planToMove,reason,currentLiving,impFactor) =>
  await axios.post('buyerprofile/',
    qs.stringify({
      phone: phone,
      age: age,
      income: income,
      buyingExperience: buyingExperience,
      adults: adults,
      childern: children,
      pets: pets,
      budgetMin: budgetMin,
      budgetMax: budgetMax,
      paymentPlan: paymentPlan,
      propertyUse: propertyUse,
      lookingFor: lookingFor,
      planToMove: planToMove,
      reason: reason,
      style : style,
      currentLiving: currentLiving,
      impFactor: impFactor,
    })
  ).then((response)=>{
    console.log(response.data)
    return response.data
  }).catch(function (error) {
    if(error.response != null) {
      return error.response.data;
    }
    else {
      return error;
    }
  });



function* storeProfileNow({payload}) {
  const {phone,style,age,income, buyingExperience,adults,children,pets,budgetMin,budgetMax,paymentPlan,propertyUse,lookingFor,planToMove,reason,currentLiving,impFactor} = payload.data;
  const { history } = payload;
  console.log(history)
  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
    const data = yield call(storeProfileNowAsync, phone,age,style,income, buyingExperience,adults,children,pets,budgetMin,budgetMax,paymentPlan,propertyUse,lookingFor,planToMove,reason,currentLiving,impFactor);
    console.log(data);
    if (data.status === 0) {
      console.log("Profile STORED Error")
      yield put(storeProfileError(data.message));
        history.replace('/app/profile')
    } else if (data.status === 1) {
      console.log("Profile STORED Successfully")
     yield  put(storeProfileSuccess(data));
      // yield put(storeProfileSuccess(data.message));
      // console.log("History check in saga" , history)
        history.replace('/app/profile')
    }
    else {
      yield put(storeProfileError(data.message));
        history.replace('/app/profile')
    }
  } catch (error) {
    yield put(storeProfileError(error));
      history.replace('/app/profile')
  }
}

export function* watchstoreProfile() {
  yield takeEvery(STORE_PROFILE, storeProfileNow);
}
export default function* rootSaga() {
  yield all([
    fork(watchgetallprofile),
    fork(watchstoreProfile),
  ])

}
