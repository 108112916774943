import React from 'react';
import {Form, Input,Select, Button} from 'antd';


const FormItem = Form.Item;
const { Option } = Select;
const SubForm3 = (props) =>{

  const { values, handleChange,handleSelect, form: {getFieldDecorator} } = props;
  const Continue = e => {
    e.preventDefault();
    props.nextStep(props.history);
  };
  const Back = e => {
    e.preventDefault();
    props.prevStep();
  };

  return(
    <span className="gx-text-center gx-mt-4">

       <Form.Item label="Looking for" hasFeedback>
          {getFieldDecorator('lookingFor', {
            initialValue: values && values.lookingFor ? values.lookingFor : undefined,
            rules: [{  message: 'Select this option!' }],
          })(
            <Select
              placeholder="How long have you been looking for a new home?"
              onChange={handleSelect("lookingFor")}
            >
              <Option value="0-3 Months">0-3 Months</Option>
              <Option value="3-6 Months">3-6 Months</Option>
              <Option value="6-12 Months">6-12 Months</Option>
              <Option value="1+ Years">1+ Years</Option>
            </Select>
          )}
        </Form.Item>

      <Form.Item label="Plan to move" hasFeedback>
          {getFieldDecorator('planToMove', {
            initialValue: values && values.planToMove ? values.planToMove : undefined,
            rules: [{ message: 'Select plan to move!' }],
          })(
            <Select
              placeholder="How soon are you planning to move?"
              onChange={handleSelect("planToMove")}
            >
              <Option value="0-3 Months">0-3 Months</Option>
              <Option value="3-6 Months">3-6 Months</Option>
              <Option value="6-12 Months">6-12 Months</Option>
              <Option value="1+ Years">1+ Years</Option>
            </Select>
          )}
        </Form.Item>

           <FormItem label="Reason" hasFeedback>
             {getFieldDecorator('reason', {
               initialValue: values && values.reason ? values.reason : undefined,
               rules: [{
                 message: 'Enter reason',
               }],
             })(
               <Select
                 placeholder="Why are you looking?"
                 onChange={handleSelect("reason")}
               >
                 <Option value="Relocating">Relocating</Option>
                 <Option value="Upsizing">Upsizing</Option>
                 <Option value="Downsizing">Downsizing</Option>
                 <Option value="Just ready for a change">Just ready for a change</Option>
                 <Option value="Build personal equity rather than renting">Build personal equity rather than renting</Option>
               </Select>
             )}
           </FormItem>

       <Form.Item label="Current Living" hasFeedback>
          {getFieldDecorator('currentLiving', {
            initialValue: values && values.currentLiving ? values.currentLiving : undefined,
            rules: [{  message: 'Select an option!' }],
          })(
            <Select
              placeholder="Where do you currently live?"
              onChange={handleSelect("currentLiving")}
            >
              <Option value="Local [In-Town]">Local [In-Town]</Option>
              <Option value="In-State">In-State</Option>
              <Option value="Out of State">Out of State</Option>
              <Option value="Out of Country">Out of Country</Option>
            </Select>
          )}
        </Form.Item>

          <FormItem className="gx-text-center">
              <Button
                // disabled={
                //   (!(props.form.isFieldTouched('lookingFor') && props.form.isFieldTouched('planToMove') && props.form.isFieldTouched('reason') && props.form.isFieldTouched('currentLiving')
                //     && !(props.form.getFieldError('lookingFor')) && !(props.form.getFieldError('planToMove'))  && !(props.form.getFieldError('reason'))  && !(props.form.getFieldError('currentLiving'))    ))
                //  }
                onClick={Continue}
                type="primary">
                Next
              </Button>
             <Button
               onClick={Back}
               type="warning"
               htmlType="submit">
            Back
          </Button>
            </FormItem>

        </span>
  )
}

export default SubForm3;



