import {GET_ALL_PROFILE , GET_ALL_PROFILE_SUCCESS, GET_ALL_PROFILE_ERROR, STORE_PROFILE, STORE_PROFILE_SUCCESS,STORE_PROFILE_ERROR} from "constants/ActionTypes";


const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  data: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_PROFILE: {return {...state, loader: true,}}
    case GET_ALL_PROFILE_SUCCESS: {return {...state, loader: false, data:action.payload}}
    case GET_ALL_PROFILE_ERROR: {return {...state, loader: false , alertMessage:action.payload , showMessage:true}}

    case STORE_PROFILE: {return {...state, loader: true}}
    case STORE_PROFILE_SUCCESS: {return {...state, loader: false, data:action.payload.data.data,alertMessage:"Profile Created Successfully!", showMessage: true}}
    case STORE_PROFILE_ERROR: {return {...state, loader: false , alertMessage:action.payload , showMessage:true}}

    default:
      return state;
  }
}
