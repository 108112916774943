import React, {Component} from "react";
import {Button, Checkbox, Form, Icon, Input, message} from "antd";


import {
  userChangePassword,
  userChangeAgent,
  hideMessage,
  showAuthLoader
} from "../../../appRedux/actions/Auth";
import {connect} from "react-redux";

const FormItem = Form.Item;

class SignUP extends Component {

  state = {
    confirmDirty: false,

  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['confirm','current','password', 'email'],(err, values) => {
      if (!err) {
        this.props.showAuthLoader();
        this.props.userChangePassword(values, this.props.history);
      }
    });
  };

  handleSubmit1 = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['agent', 'email'],(err, values) => {
      if (!err) {
        this.props.showAuthLoader();
        this.props.userChangeAgent(values, this.props.history);
      }
    });
  };

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
        this.props.form.resetFields();
      }, 100);
    }
  }


  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.props.confirmDirty) {
      form.validateFields(['confirm'], {force: true});
    }
    callback();
  };

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({confirmDirty: this.state.confirmDirty || !!value});
  }

  render() {

    const {getFieldDecorator} = this.props.form;
    const {showMessage, loader, alertMessage} = this.props;

    return (
      <div className="gx-login-container">
        <div className="gx-login-content">
        <div className="gx-login-header gx-text-center">
          <h1 className="gx-login-title">Change Password</h1>
        </div>
        <Form onSubmit={this.handleSubmit} className="gx-login-form gx-form-row0">

          <Form.Item hidden={true}>
            {getFieldDecorator('email',{
              initialValue: this.props.authUser && this.props.authUser.email,
            })(
              <Input type="text"/>
            )}
          </Form.Item>


          <FormItem>
            {getFieldDecorator('current', {
              rules: [
                {
                  required: true,
                  message: 'Please input your current Password!'
                }
              ],
            })(
              <Input.Password prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>} type="password"
                              placeholder="Current Password"/>
            )}
          </FormItem>
          <FormItem hasFeedback>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please input your new Password!'
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(
              <Input.Password prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>} type="password"
                              placeholder="New Password" onBlur={this.handleConfirmBlur}/>
            )}
          </FormItem>
          <FormItem hasFeedback>
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: true, message: 'Please confirm your new Password!'
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(
              <Input.Password prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>} type="password"
                              placeholder="Confirm New Password" onBlur={this.handleConfirmBlur}/>
            )}
          </FormItem>


          {/*{loader ?
              <div>
                <CircularProgress className={'gx-loader-view'}/>
              </div> : null}*/}
          {showMessage ?
            message.info(alertMessage.toString()) : null}
          <FormItem className="gx-text-center">
            <Button
              loading={loader}
              type="primary"
              htmlType="submit">
              Change
            </Button>
          </FormItem>
        </Form>


          <div className="gx-login-header gx-text-center">
            {this.props.authUser.agentEmail === null &&
            <h1 className="gx-login-title">Add Agent</h1>
            }
            {this.props.authUser.agentEmail != null &&
            <h1 className="gx-login-title">Change Agent</h1>
            }
          </div>
          <Form onSubmit={this.handleSubmit1} className="gx-login-form gx-form-row0">

            <FormItem hasFeedback>
              {getFieldDecorator('agent', {
                rules: [{
                  required: true, type: 'email', message: 'The input is not valid E-mail!',
                }],
              })(
                <Input placeholder="New Agent Email"/>
              )}
            </FormItem>

            <FormItem className="gx-text-center">
              <Button
                loading={loader}
                type="primary"
                htmlType="submit">
                Change
              </Button>
            </FormItem>
          </Form>
      </div>

      </div>
    );
  }
}

const WrappedNormalSignUpForm = Form.create()(SignUP);

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userChangePassword,
  hideMessage,
  showAuthLoader,
  userChangeAgent
})(WrappedNormalSignUpForm);
