import {GET_BUYER_PROPERTIES ,GET_BUYER_PROPERTIES_SUCCESS,
  GET_BUYER_PROPERTIES_ERROR,DELETE_BUYER_PROPERTY,DELETE_BUYER_PROPERTY_SUCCESS,DELETE_BUYER_PROPERTY_ERROR,POST_REPLIES, POST_REPLIES_ERROR, POST_REPLIES_SUCCESS} from "constants/ActionTypes";

export const getBuyerProperties = () => {
  return {
    type: GET_BUYER_PROPERTIES,
  };
};export const getBuyerPropertiesSuccess = (data) => {
  return {
    type: GET_BUYER_PROPERTIES_SUCCESS,
    payload: data
  };
};export const getBuyerPropertiesError = (message) => {
  return {
    type: GET_BUYER_PROPERTIES_ERROR,
    payload:message
  };
};

//Delete Buyer Property by Property ID
export const deleteBuyerProperty = (id, history) => {
  return {
    type: DELETE_BUYER_PROPERTY,
    payload: {id, history}
  };
};export const deleteBuyerPropertySuccess = (message) => {
  return {
    type: DELETE_BUYER_PROPERTY_SUCCESS,
    payload:message
  };
};export const deleteBuyerPropertyError = (message) => {
  return {
    type: DELETE_BUYER_PROPERTY_ERROR,
    payload:message
  };
};

export const postReplies = (data, history) => {
  return {
    type: POST_REPLIES,
    payload: {data, history}
  };
};export const postRepliesSuccess = (message) => {
  return {
    type: POST_REPLIES_SUCCESS,
    payload: message
  };
};export const postRepliesError = (message) => {
  return {
    type: POST_REPLIES_ERROR,
    payload:message
  };
};


