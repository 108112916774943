import React from 'react';
import {Form, Input,Select, Button} from 'antd';
import {parsePhoneNumber} from 'libphonenumber-js'

const FormItem = Form.Item;
const { Option } = Select;
const SubForm2 = (props) =>{

  const { values,handleSelect, form: {getFieldDecorator} } = props;
  const Continue = e => {
    e.preventDefault();
    props.nextStep(props.history);
  };

  const Back = e => {
    e.preventDefault();
    props.prevStep();
  };

  const handlePhone = () => {
    const {setFieldsValue,getFieldValue} = props.form;
    const phone = getFieldValue('phone');
    const pattern = "^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$";
    let newPhone,newPhonee;
    if (phone !== "")
    {
      if (phone.toString().match(pattern))
      {
        newPhone = parsePhoneNumber('+1' + phone);
        newPhonee = newPhone ? newPhone.formatNational() : "";
        setFieldsValue({
          phone : newPhonee
        })

      }
      else
      {
        newPhonee = "";
        setFieldsValue({
          phone : newPhonee
        })
      }

    }
    else {
      newPhonee = "";
      setFieldsValue({
        phone : newPhonee
      })
    }

    props.handlePhoneNumber(newPhonee);
  }

  return(
    <span className="gx-text-center gx-mt-4">
           <FormItem label="Phone" hasFeedback>
             {getFieldDecorator('phone', {
               initialValue: values && values.phone,
               rules: [{

                 message: 'Please input valid phone number',
                 // pattern : '^\\(?([0-9]{0,3})\\)?[-. ]?([0-9]{0,3})[-. ]?([0-9]{0,4})$'
               }
               ],
             })(
               <Input onBlur = {handlePhone} placeholder="Enter 10 digits phone"
               />
             )}
           </FormItem>


      <Form.Item label="Age Range" hasFeedback>
          {getFieldDecorator('age', {
            initialValue: values && values.age ? values.age : undefined,
            rules: [{  message: 'Please select your age range!' }],
          })(
            <Select
              placeholder="Select your age range?"
              onChange={handleSelect("age")}

            >

              <Option value="18-24">18-24</Option>
              <Option value="25-34">25-34</Option>
              <Option value="35-44">35-44</Option>
              <Option value="45-54">45-54</Option>
              <Option value="55-64">55-64</Option>
              <Option value="65 or more">65 or more</Option>
            </Select>
          )}
        </Form.Item>

      <Form.Item label="Income Range" hasFeedback>
          {getFieldDecorator('income', {
            initialValue: values && values.income ? values.income  : undefined,
            rules: [{  message: 'Please select your income range!' }],
          })(
            <Select
              placeholder="Select your income range?"
              onChange={handleSelect("income")}
            >
              <Option value="$0-$40,000">$0-$40,000</Option>
              <Option value="$40,000-$80,000">$40,000-$80,000</Option>
              <Option value="$80,000-$150,000">$80,000-$150,000</Option>
              <Option value="$150,000-$300,000">$150,000-$300,000</Option>
              <Option value="$300,000+">$300,000+</Option>
            </Select>
          )}
        </Form.Item>

      <Form.Item label="Buying Experience" hasFeedback>
          {getFieldDecorator('buyingExperience', {
            initialValue: values && values.buyingExperience ? values.buyingExperience : undefined,
            rules: [{  message: 'Please, select your buying experience!' }],
          })(
            <Select
              placeholder="Select your buying experience?"
              onChange={handleSelect("buyingExperience")}
            >
              <Option value="First Time">First Time</Option>
              <Option value="1-2 Prior Homes">1-2 Prior Homes</Option>
              <Option value="3+">3+</Option>
            </Select>
          )}
        </Form.Item>

          <FormItem className="gx-text-center">
              <Button
                // disabled={
                //   (!(props.form.isFieldTouched('phone') && props.form.isFieldTouched('age') && props.form.isFieldTouched('income') && props.form.isFieldTouched('buyingExperience')
                //     && !(props.form.getFieldError('phone')) && !(props.form.getFieldError('age')) && !(props.form.getFieldError('income')) && !(props.form.getFieldError('buyingExperience'))))
                // }
                onClick={Continue}
                type="primary">
                Next
              </Button>
            </FormItem>

        </span>
  )
}

export default SubForm2;


