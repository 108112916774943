import React from "react";
import { Form, Button } from "antd";

import SubForm1 from "./components/SubForm1";
import SubForm2 from "./components/SubForm2";
import SubForm3 from "./components/SubForm3";
import SubForm4 from "./components/SubForm4";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not validate email!",
    //  number: '${label} is not a validate number!',
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
  string: {
    range: "${label} must be between ${min} and ${max} characters",
  },
  password: {
    range: "${label} must be between ${min} and ${max} characters",
  },
};

const MasterForm = (props) => {
  const { values, handleChange, form} = props;
  const onFinish = (values) => {
    console.log("Finish:", values);
    console.log("Props:", props);
  };
  const FormItem = Form.Item;

  return (
    <Form
      form={form}
      {...layout}
      name="nest-messages"
      validateMessages={validateMessages}
    >
      {(() => {
        switch (props.step) {
          case 1:
            return (
              <SubForm1
                form={form}
                history={props.history}
                nextStep={props.nextStep}
                handlePhoneNumber={props.handlePhoneNumber}
                values={props.values}
                handleSelect = {props.handleSelect}
              />
            );
          case 2:
            return (
              <SubForm2
                form={form}
                history={props.history}
                nextStep={props.nextStep}
                prevStep={props.prevStep}
                handleChange={props.handleChange}
                values={props.values}
                handleSelect = {props.handleSelect}
              />
            );
            case 3:
            return (
              <SubForm3
                form={form}
                history={props.history}
                nextStep={props.nextStep}

                prevStep={props.prevStep}
                handleChange={props.handleChange}
                values={props.values}
                handleSelect = {props.handleSelect}
              />
            );
          case 4:
            return (
              <SubForm4
                form={form}
                history={props.history}
                nextStep={props.nextStep}
                prevStep={props.prevStep}
                handleChange={props.handleChange}
                values={props.values}
                handleSelect = {props.handleSelect}
              />
            );

          default:
            console.log("This is a multi-step form built with React.");
        }
      })()}


    </Form>
  );
};

export default MasterForm;
