import React from "react";
import {Button, Col, Row, Tabs} from "antd";
import Widget from "components/Widget";
import {connect} from "react-redux";
import CommonForm from "../../../routes/app/profileEdit/index";
import {
  getDashboard,
} from "../../../appRedux/actions";
import {Link} from "react-router-dom";

const TabPane = Tabs.TabPane;

class About extends React.Component {

  // componentDidMount() {
  //    this.props.getDashboard()
  // }

  render() {
    const {authUser} = this.props;

    return (
      <Widget title="Your Preferences" styleName="gx-card-tabs gx-card-tabs-right gx-card-profile">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Overview" key="1">
            {authUser && authUser.buyerProfile  ?
            <div className="gx-mb-2">
              <Row>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-datepicker gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Age Range</h6>
                      <p className="gx-mb-0">{authUser && authUser.buyerProfile && authUser.buyerProfile.age ? authUser.buyerProfile.age : "N/A"}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-phone gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Phone</h6>
                      <p className="gx-mb-0">{authUser && authUser.buyerProfile && authUser.buyerProfile.phone ? authUser.buyerProfile.phone : "N/A"}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-revenue-new gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Income Range</h6>
                      <p className="gx-mb-0">{authUser && authUser.buyerProfile && authUser.buyerProfile.style ? authUser.buyerProfile.style : "N/A"}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-ckeditor gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Buying Experience</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{authUser && authUser.buyerProfile && authUser.buyerProfile.buyingExperience ? authUser.buyerProfile.buyingExperience : "N/A"}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-growth gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Budget</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{authUser && authUser.buyerProfile && "$"+authUser.buyerProfile.budgetMin} - {authUser.buyerProfile && "$"+authUser.buyerProfile.budgetMax}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-pricing-table gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Payment Plan</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{authUser && authUser.buyerProfile && authUser.buyerProfile.paymentPlan ? authUser.buyerProfile.paymentPlan : "N/A"}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-company gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Use of Property</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{authUser && authUser.buyerProfile && authUser.buyerProfile.propertyUse ? authUser.buyerProfile.propertyUse : "N/A"}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-home gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Currently Living</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{authUser && authUser.buyerProfile && authUser.buyerProfile.currentLiving ? authUser.buyerProfile.currentLiving : "N/A"}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-calendar gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Looking For</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{authUser && authUser.buyerProfile && authUser.buyerProfile.lookingFor ? authUser.buyerProfile.lookingFor : "N/A"}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-calendar-new gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Plan To Move</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{authUser && authUser.buyerProfile && authUser.buyerProfile.planToMove ? authUser.buyerProfile.planToMove : "N/A"}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-exclamation gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Reason</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{authUser && authUser.buyerProfile && authUser.buyerProfile.reason ? authUser.buyerProfile.reason : "N/A"}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-important gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Important Factor</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{authUser && authUser.buyerProfile && authUser.buyerProfile.impFactor ? authUser.buyerProfile.impFactor : "N/A"}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-map-drawing gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Property Styling</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{authUser && authUser.buyerProfile && authUser.buyerProfile.income  ? authUser.buyerProfile.income : "N/A"}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-family gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Family Members</h6>
                      <p className="gx-mb-0 gx-text-capitalize">Adults: {authUser && authUser.buyerProfile && authUser.buyerProfile.adults ? authUser.buyerProfile.adults : "N/A"}<br/>{"\n"}Children: {authUser.buyerProfile && authUser.buyerProfile.childern ? authUser.buyerProfile.childern : "N/A"}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-error gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Pets</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{authUser && authUser.buyerProfile && authUser.buyerProfile.pets ? authUser.buyerProfile.pets : "N/A"}</p>
                    </div>
                  </div>
                </Col>

              </Row>
            </div> :
                <div>
                  <h4>Your profile is not completed.</h4>
                  <Link to="profile/create" style={{marginTop:'100px'}}>Click here to complete your profile</Link>
                </div>

            }
          </TabPane>


        </Tabs>
      </Widget>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};
export default connect(mapStateToProps, {getDashboard})(About);
