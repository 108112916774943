import React from 'react';
import {Form, Input, Select, Button} from 'antd';
import {connect} from "react-redux";
import {message} from "antd/lib/index";
import {Link,history} from "react-router-dom";
import {
    storeProfile
} from "../../../../appRedux/actions/Profile";

const FormItem = Form.Item;
const {Option} = Select;


const SubForm4 = (props) => {

    const {values, handleChange, handleSelect, form: {getFieldDecorator}} = props;
    const {showMessage, loader, alertMessage} = props;

    const  Continue = e => {
        e.preventDefault();
        console.log(values);
        console.log(props.history);

        props.storeProfile(values, props.history)
        if(showMessage){
            message.info(alertMessage.toString())
        }
    };
    const Back = e => {
        e.preventDefault();
        props.prevStep();
    };

    return (
        <span className="gx-text-center gx-mt-4">

       <Form.Item label="Imp Factor" hasFeedback>
          {getFieldDecorator('impFactor', {
            initialValue: values && values.impFactor ? values.impFactor : undefined,
              rules: [{ message: 'Select this option!'}],
          })(
              <Select
                  placeholder="What is the most important factor?"
                  onChange={handleSelect("impFactor")}
              >
                  <Option value="Location">Location</Option>
                  <Option value="Price">Price</Option>
                  <Option value="Style">Style</Option>
                  <Option value="Schools">Schools</Option>
              </Select>
          )}
        </Form.Item>

      <Form.Item label="Home Style" hasFeedback>
          {getFieldDecorator('style', {
            initialValue: values && values.style ? values.style : undefined,

            rules: [{ message: 'Select style!'}],
          })(
              <Select
                  placeholder="What style home are you looking for?"
                  onChange={handleSelect("style")}
              >
                  <Option value="Modern">Modern</Option>
                  <Option value="Traditional">Traditional</Option>
                  <Option value="Victorian">Victorian</Option>
                  <Option value="Contemporary">Contemporary</Option>
              </Select>
          )}
        </Form.Item>

           <FormItem label="Adults" hasFeedback>
             {getFieldDecorator('adults', {

                 initialValue: values && values.adults ? values.adults : undefined,
                 rules: [{

                     message: 'Please enter a valid value',
                 }
                 ],
             })(
                 <Input onChange={handleChange("adults")} placeholder="No of Adults in Household"/>
             )}
           </FormItem>

       <FormItem label="Children" hasFeedback>
             {getFieldDecorator('children', {
                 initialValue: values && values.children ? values.children : undefined,
                 rules: [{

                     message: 'Please enter a valid value',
                 }
                 ],
             })(
                 <Input onChange={handleChange("children")} placeholder="No of Children in Household"/>
             )}
           </FormItem>

       <Form.Item label="Pets" hasFeedback>
          {getFieldDecorator('pets', {
            initialValue: values && values.pets ? values.pets : undefined,
              rules: [{ message: 'Select pets!'}],
          })(
              <Select
                  placeholder="Household Pets?"
                  onChange={handleSelect("pets")}
              >
                  <Option value="None">None</Option>
                  <Option value="Cats">Cats</Option>
                  <Option value="Dogs">Dogs</Option>
                  <Option value="Other">Other</Option>
              </Select>
          )}
        </Form.Item>

          <FormItem className="gx-text-center">
              <Button
                  loading={loader}
                  // disabled={
                  //     (!(props.form.isFieldTouched('impFactor') && props.form.isFieldTouched('style') && props.form.isFieldTouched('adults') && props.form.isFieldTouched('children') && props.form.isFieldTouched('pets')
                  //         && !(props.form.getFieldError('impFactor')) && !(props.form.getFieldError('style')) && !(props.form.getFieldError('adults')) && !(props.form.getFieldError('children')) && !(props.form.getFieldError('pets'))    ))
                  // }
                  onClick={Continue}
                  type="primary">
               Submit
              </Button>
             <Button

                 onClick={Back}
                 type="warning"
                 htmlType="submit">
            Back
          </Button>
            </FormItem>

        </span>

    )

}

const mapStateToProps = ({profile}) => {
    const {loader, alertMessage, showMessage} = profile;
    return {loader, alertMessage, showMessage}
};


export default connect(mapStateToProps, {
    storeProfile
})(SubForm4);



