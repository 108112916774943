import {GET_ALL_PROFILE , GET_ALL_PROFILE_SUCCESS, GET_ALL_PROFILE_ERROR, STORE_PROFILE, STORE_PROFILE_SUCCESS,STORE_PROFILE_ERROR} from "constants/ActionTypes";


export const getAllProfile = () => {
  return {
    type: GET_ALL_PROFILE
  };
};export const getAllProfileSuccess = (data) => {
  return {
    type: GET_ALL_PROFILE_SUCCESS,
    payload:data
  };
};export const getAllProfileError = (data) => {
  return {
    type: GET_ALL_PROFILE_ERROR,
    payload:data
  };
};

export const storeProfile = (data, history) => {
  return {
    type: STORE_PROFILE,
    payload : {data, history}
  };
};export const storeProfileSuccess = (data) => {
  return {
    type: STORE_PROFILE_SUCCESS,
    payload: {data}
  };
};export const storeProfileError = (message) => {
  return {
    type: STORE_PROFILE_ERROR,
    payload:message
  };
};
