import React from "react";
import Widget from "components/Widget";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
const Contact = ({authUser}) => {
  return (
    <Widget title="Your Agent" styleName="gx-card-profile-sm">
      {authUser && authUser.agent && authUser.agent.firstName &&
      <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
        <div className="gx-mr-3">
          <i className="icon icon-user gx-fs-xxl gx-text-grey"/>
        </div>
        <div className="gx-media-body">
          <span className="gx-mb-0 gx-text-grey gx-fs-sm">Name</span>
          <p className="gx-mb-0">
            {authUser.agent.firstName} {authUser.agent.lastName}
          </p>
        </div>
      </div>
      }
      {authUser && authUser.agent && authUser.agent.email &&
      <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
        <div className="gx-mr-3">
          <i className="icon icon-email gx-fs-xxl gx-text-grey"/>
        </div>
        <div className="gx-media-body">
          <span className="gx-mb-0 gx-text-grey gx-fs-sm">Email</span>
          <p className="gx-mb-0">{authUser && authUser.agent.email}</p>
        </div>
      </div>
      }
      {authUser && authUser.agent && authUser.agent.agentProfile && authUser.agent.agentProfile.phone != null &&
      <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
        <div className="gx-mr-3">
          <i className="icon icon-phone gx-fs-xxl gx-text-grey"/>
        </div>
        <div className="gx-media-body">
          <span className="gx-mb-0 gx-text-grey gx-fs-sm">Phone</span>
          <p className="gx-mb-0">{authUser && authUser.agent.agentProfile &&  authUser.agent.agentProfile.phone}</p>
        </div>
      </div>
      }
      {authUser && authUser.agent && authUser.agent.agentProfile == null &&
      <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
        <div className="gx-mr-3">
          <i className="icon icon-phone gx-fs-xxl gx-text-grey"/>
        </div>
        <div className="gx-media-body">
          <span className="gx-mb-0 gx-text-grey gx-fs-sm">Phone</span>
          <p className="gx-mb-0">N/A</p>
        </div>
      </div>
      }
      {authUser && authUser.agent && authUser.agent.agentProfile != null && authUser.agent.agentProfile.phone == null &&
      <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
        <div className="gx-mr-3">
          <i className="icon icon-phone gx-fs-xxl gx-text-grey"/>
        </div>
        <div className="gx-media-body">
          <span className="gx-mb-0 gx-text-grey gx-fs-sm">Phone</span>
          <p className="gx-mb-0">N/A</p>
        </div>
      </div>
      }
      {authUser && authUser.agentEmail && authUser.agent == null &&
      <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
        <div className="gx-mr-3">
          <i className="icon icon-email gx-fs-xxl gx-text-grey"/>
        </div>
        <div className="gx-media-body">
          <span className="gx-mb-0 gx-text-grey gx-fs-sm">Email</span>
          <p className="gx-mb-0">{authUser && authUser.agentEmail}</p>
        </div>
      </div>
      }
      {authUser && authUser.agentEmail === null &&
      <Link to="/app/setting">
      <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
        <div className="gx-media-body">
          <p className="gx-mb-0">Add Your Agent</p>
        </div>
      </div>
      </Link>
      }

    </Widget>
  )
}

const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};
export default connect(mapStateToProps, null)(Contact);
