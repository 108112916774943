import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_AGENT_SUCCESS,
  OTP_VERIFIED,
  OTP_RESEND,

  FORGET_PASSWORD_EMAIL_SUCCESS,
  FORGET_PASSWORD_VERIFICATION_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,

  GET_DASHBOARD_SUCCESS
} from "constants/ActionTypes";
import {OTP_VERIFIED_ERROR, OTP_VERIFIED_SUCCESS} from "../../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: null,
  email: '',
  ChangePassword: [],
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        initURL: '/OTP',
        authUser: action.payload
      }
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }

    case GET_DASHBOARD_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }

    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }

    case CHANGE_AGENT_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }

    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        authUser: null,
        initURL: '/',
        loader: false
      }
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_TWITTER_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case OTP_VERIFIED: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case OTP_VERIFIED_SUCCESS: {
      return {
        authUser: null,
        initURL: '/',
        loader: false,
        showMessage: true,
        alertMessage: action.payload
      }
    }
    case OTP_VERIFIED_ERROR: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case OTP_RESEND: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }

    case FORGET_PASSWORD_EMAIL_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }

    case FORGET_PASSWORD_VERIFICATION_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }

    case RESET_PASSWORD: {return {...state, loader: true,}}

    case RESET_PASSWORD_SUCCESS: {
      return {
        authUser: null,
        initURL: '/',
        loader: false
      }
    }
    default:
      return state;
  }
}
