import React from "react";
import {Col, Row} from "antd";

import Metrics from "components/Metrics";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

const TotalProperties = ({data}) => {
  return (
    <Metrics styleName={`gx-card-full`} title="Properties">
      <Link to="/app/listing">
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="gx-pb-2 gx-pl-4 gx-pt-1">
            <h1 className="gx-fs-xxxl gx-font-weight-medium gx-chart-up gx-pt-3">{data ? data.length : 0}</h1>
            <p className="gx-text-grey gx-text-capitalize">Shared by You</p>
          </div>
        </Col>
      </Row>
      </Link>
    </Metrics>

  );
};

const mapStateToProps = ({property}) => {
  const {data} = property;
  return {data}
};
export default connect(mapStateToProps, null)(TotalProperties);
