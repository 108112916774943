import React from "react";

import {Col, Menu, Row} from "antd";

import Metrics from "components/Metrics";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

const MyAgentCard = ({authUser}) => {
  return (
    <Metrics styleName={`gx-card-full gx-pointer`} title="Your Agent">
      <Row>
        <Link to="/app/settings">
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="gx-pb-2 gx-pl-4 gx-pt-1">
            <h1 className="gx-fs-xxxl gx-font-weight-medium gx-chart-up gx-pt-3">
              {authUser && authUser.agent == null && authUser.agentEmail != null && "Registration Awaited"}
              {authUser && authUser.agent != null && authUser.agent.firstName && authUser.agent.firstName+" "+authUser.agent.lastName}
              {authUser && authUser.agentEmail == null && "Add Your Agent"}
            </h1>
            <p className="gx-text-grey gx-text-capitalize">You can change anytime in settings</p>
          </div>
        </Col>
        </Link>
      </Row>
    </Metrics>
  );
};

const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};
export default connect(mapStateToProps, null)(MyAgentCard);
