import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Dashboard from "./dashboard";
import Profile from "./profile";
import Setting from "./setting";
import GetBuyerProperties from "./dashboard/getBuyerProperties";
import propertyDetail from "./dashboard/propertyDetail";
import CommonForm from "./profileEdit";
import Chat from '../../components/chat/index'

const App = ({match}) => (
  <Switch>
    <Route path={`${match.url}/dashboard`} component={Dashboard} exact/>
    <Route path={`${match.url}/profile`} component={Profile} exact/>
    <Route path={`${match.url}/profile/create`} component={CommonForm} exact/>
    <Route path={`${match.url}/settings`} component={Setting} exact/>
    <Route path={`${match.url}/listing`} component={GetBuyerProperties} exact/>
    <Route path={`${match.url}/listing/detail`} component={propertyDetail} exact/>
    <Route path={`${match.url}/comments`} component={Chat} exact/>

  </Switch>
);

export default App;
