import {GET_BUYER_PROPERTIES,
  GET_BUYER_PROPERTIES_SUCCESS,
  GET_BUYER_PROPERTIES_ERROR,
  DELETE_BUYER_PROPERTY,DELETE_BUYER_PROPERTY_SUCCESS,DELETE_BUYER_PROPERTY_ERROR,
  POST_REPLIES, POST_REPLIES_ERROR, POST_REPLIES_SUCCESS
} from "constants/ActionTypes";


const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  data: null,

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BUYER_PROPERTIES: {return {...state, loader: true,}}
    case GET_BUYER_PROPERTIES_SUCCESS: {return {...state, loader: false, data: action.payload}}
    case GET_BUYER_PROPERTIES_ERROR: {return {...state, loader: false , alertMessage:action.payload , showMessage:true}}

    case DELETE_BUYER_PROPERTY: {return {...state, loader: true,}}
    case DELETE_BUYER_PROPERTY_SUCCESS: {return {...state, loader: false, alertMessage:action.payload , showMessage:true}}
    case DELETE_BUYER_PROPERTY_ERROR: {return {...state, loader: false , alertMessage:action.payload , showMessage:true}}

    case POST_REPLIES: {return {...state, loader: true,}}
    case POST_REPLIES_SUCCESS: {return {...state, loader: false, replies:action.payload}}
    case POST_REPLIES_ERROR: {return {...state, loader: false , alertMessage:action.payload , showMessage:true}}


    default:
      return state;
  }
}
