import React, { Component } from "react";
import MasterForm from "./MasterForm";
import {Steps, Row, Col, Form} from "antd";
import './MultiStepForm.css';
import {connect} from "react-redux";


const { Step } = Steps;

const steps = [
  {
    title: "Step 1",
  },
  {
    title: "Step 2",
  },
  {
    title: "Step 3",
  },
  {
    title: "Step 4"
  }
];

class Index extends Component {
  constructor(props) {
    super(props);

  }
  state = {
    step: 1,
    phone: this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.phone  ? this.props.authUser.buyerProfile.phone : "",
    age: this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.age ? this.props.authUser.buyerProfile.age : "",
    income : this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.income ? this.props.authUser.buyerProfile.income : "",
    buyingExperience : this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.buyingExperience ? this.props.authUser.buyerProfile.buyingExperience : "",

    budgetMin : this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.budgetMin  ? this.props.authUser.buyerProfile.budgetMin : "",
    budgetMax : this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.budgetMax ? this.props.authUser.buyerProfile.budgetMax : "",
    paymentPlan : this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.paymentPlan  ? this.props.authUser.buyerProfile.paymentPlan : "",
    propertyUse : this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.propertyUse ? this.props.authUser.buyerProfile.propertyUse : "",

    lookingFor : this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.lookingFor ? this.props.authUser.buyerProfile.lookingFor : "",
    planToMove : this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.planToMove ? this.props.authUser.buyerProfile.planToMove : "",
    reason : this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.reason ? this.props.authUser.buyerProfile.reason : "",
    currentLiving : this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.currentLiving ? this.props.authUser.buyerProfile.currentLiving : "",

    impFactor : this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.impFactor ? this.props.authUser.buyerProfile.impFactor : "",
    style : this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.style ? this.props.authUser.buyerProfile.style : "",
    adults : this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.adults ? this.props.authUser.buyerProfile.adults : "",
    children : this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.children ? this.props.authUser.buyerProfile.children : "",
    pets : this.props.authUser && this.props.authUser.buyerProfile && this.props.authUser.buyerProfile.pets ? this.props.authUser.buyerProfile.pets : "",


    country: "",
    password: "",
  };

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step} = this.state;
    this.setState({
      step: step - 1,
    });

  };

  // Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };
  handlePhoneNumber = (value) => {
    this.setState({ phone: value });
  };
  handleSelectChange = (input) => (value) => {
    this.setState({ [input]: value });
  };

  render() {
    const {form} = this.props;
    const {step} = this.state;
    const { phone,age,income,style, buyingExperience,adults,children,pets,budgetMin,budgetMax,paymentPlan,propertyUse,lookingFor,planToMove,reason,currentLiving,impFactor,
    } = this.state;
    const values = {phone,age,income,style, buyingExperience,adults,children,pets,budgetMin,budgetMax,paymentPlan,propertyUse,lookingFor,planToMove,reason,currentLiving,impFactor};
    const stepp = step;

    return (
      <Row>
        <Col span={24}>
          <Steps current={stepp-1}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Col>

        <Col span={20}>
          <div className="steps-content">
            <MasterForm
              form={form}
              history={this.props.history}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              handlePhoneNumber = {this.handlePhoneNumber}
              values={values}
              step={stepp}
              handleSelect={this.handleSelectChange}
            />
          </div>

        </Col>

      </Row>
    );
  }
}
const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};
const CommonForm = Form.create()(Index)
export default connect(mapStateToProps, null)(CommonForm);


